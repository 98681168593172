<template>
  <b-card
    v-if="pgs"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Visão geral dos PGs</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        {{ totalPgs }} PGs ativos
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in pgs"
          :key="item.icon"
          xl="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      pgs: [],
      totalPgs: "0"
    }
  },
  created() {
    this.$httpReport.post('/executor', {
  	"key": "QUANTIDADE_PGS_ATIVOS_POR_REDE",
	  "params": {}
  }).then(response => {      
    let pgs = response.data.data

    this.pgs.push({
      color: "light-info",
      customClass: "mb-2 mb-xl-0",
      icon: "UserIcon",
      subtitle: "Rede de Homens",
      title: pgs['Rede de Homens'] + ' pgs'
    });

    this.pgs.push({
      color: "light-danger",
      customClass: "mb-2 mb-xl-0",
      icon: "ActivityIcon",
      subtitle: "Rede de Mulheres",
      title: pgs['Rede de Mulheres'] + ' pgs'
    });

    this.pgs.push({
      color: "light-warning",
      customClass: "mb-2 mb-xl-0",
      icon: "UsersIcon",
      subtitle: "Rede de Casais",
      title: pgs['Rede de Casais'] + ' pgs'
    });

    this.pgs.push({
      color: "light-success",
      customClass: "mb-2 mb-xl-0",
      icon: "StarIcon",
      subtitle: "Rede de Jovens",
      title: pgs['Rede de Jovens'] + ' pgs'
    });
  })

  this.$httpReport.post('/executor', {
  	"key": "QUANTIDADE_PGS_ATIVOS",
	  "params": {}
  }).then(response => {      
    let qtd = response.data.data
    this.totalPgs = qtd.total
  })
  }
}
</script>
